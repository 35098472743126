<template>
  <div class="specialStock"> 
    <div class="special-stock-top">
      <div class="search-l">{{ $t('列表为已入门店库的可用特殊车辆（不含退役展车），若在库车辆未在列表展示，可在配置单明细查看该车是否被已有配置单占用；配置单过期失效或手动取消，可释放车辆资源') }}</div>
      <div class="search-r">
        <div class="filter-panel">
          <img class="img" :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click="filterActive = true"/>
        </div>
      </div>
    </div>
    <FilterPanel
      ref="filterPanel"
      v-model="filterActive"
      :options="filterOptions"
      @submit="onFilter"
      @reset="controlModelSales"
      @beforeReset="beforeReset"
    />
    
    <!-- List -->
    <div class="totality">
      <div>{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
      <div class="btn" @click="handleConfigList">{{$t('查看配置单明细')}}</div>
      <div class="btn" @click="handleOperate">{{$t('查询中心仓库存')}}</div>
    </div>
    <List v-if="userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <Item :data="item" @go="(type)=>handleDetail(item)"></Item>
      </template>
    </List>

  </div>
</template>

<script>
// Tools
import { mapGetters } from 'vuex'
import formatParams from '@/utils/formatParams'
import { arrayFormat, debounce, isMobilePhone } from '@/utils/index.js'
import { v4 as uuidv4 } from 'uuid'

// Components
import List from '@/components/baseList/list.vue'
import Item from './components/Item.vue'
import FilterPanel from '@/components/filter-panel'

// Images
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'

// APIs
import baseDataServices from '@/services/baseDataServices'
import { getWarehouseList, queryMaterial } from '@/services/specialCar.js'
import SearchBarNew from '@/components/search-bar-new'
import mixin from './mixin'
export default {
  name: 'specialStock',
  components: { List, Item, FilterPanel, SearchBarNew },
  mixins: [mixin],
  data() {
    return {
      filterIcon,
      filterActiveIcon,
      filterActive: false,
      list: [],
      // filterOptions: [],
      filterParams: {
        specialVehicleTypes:[],
        deliveryStoreCodes:[], // 交付门店
        seriesCodes:[], // 车系
        modelCodes:[], // 车型
        colourCodes:[], // 外饰
        interiorCodes:[], // 内饰
        optionsCodes:[], // 选装
      },
      vin: '',

      countNum:0
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // getDealersId() {
    //   const { dealers } = this.userInfo || {}
    //   return dealers && dealers[0]?.id || ''
    // },
    // 交付门店
    deliveryStoreList() {
      let arr = []
      try {
        arr = arrayFormat(this.userInfo.dealers[0].relations,{
          value: 'dealerCode',
          name:'dealerName',
          cb:(item)=>({ id:item.dealerCode })
        })
      } catch (error) {
            
      }
      return arr
    },
    filterOptions() {
      const that = this
      const arr = [{
        id: uuidv4(),
        type: 'options',
        label: this.$t('车系'),
        multiple: true,
        field: 'seriesCodes',
        options: this.seriesList,
        isCache: true,        
        onChange:(arr, params={})=>{
          Object.assign(params,{
            modelCodes:[],
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(2,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('车型'),
        multiple: true,
        field: 'modelCodes',
        options: this.modelList,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(3,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('外饰'),
        multiple: true,
        field: 'colourCodes',
        options:this.outsideColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(4,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('内饰'),
        multiple: true,
        field: 'interiorCodes',
        options:this.innerColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            optionsCodes:[]
          })
          this.controlModelSales(5,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('选装'),
        multiple: true,
        field: 'optionsCodes',
        options:this.saleOptions,
        isCache: true,
      }]
      return [{
        id: uuidv4(),
        type: 'options',
        label: this.$t('交付门店'),
        multiple: true,
        field: 'deliveryStoreCodes',
        options: this.deliveryStoreList,
        defaultValue: this.filterParams.deliveryStoreCodes && this.deliveryStoreList.filter(({ value })=>this.filterParams.deliveryStoreCodes.includes(value)) || [] ,
        isCache: true,
      },{
        id: uuidv4(),
        type: 'options',
        label:this.$t('车辆类型'),
        field: 'specialVehicleTypes',
        multiple: true,
        isCache: true,
        options: this.specialVehicles
      }, ...arr]
    }
  },
  watch: {
    deliveryStoreList:{
      handler(val){
        this.setDeliveryStoreCodes(val)
      },
      immediate: true
    },
  },
  // mounted() {
  // },
  created() {
    // this.getMaterialList()
    this.controlModelSales()
  },
  methods: {
    beforeReset(options) {
      for (let i in options) {
        const item = options[i]
        if (item.field === 'deliveryStoreCodes' && this.deliveryStoreList && this.deliveryStoreList.length){
          item.defaultValue = [this.deliveryStoreList[0]]
        }
      } 
    },
    setDeliveryStoreCodes(val) {
      if (val && val.length){
        this.filterParams.deliveryStoreCodes=[val[0].value]
      }
    },
    onRefresh:debounce(function() {            
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      let vinStr = ''
      if ([8, 17].includes(this.vin.length)) vinStr = this.vin
      const page = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      const { deliveryStoreCodes=[],...query } = this.filterParams
      return getWarehouseList({
        ...page,
        ...query,
        queryType: 0, // 查询类型 0门店库存 1中心库库存
        vin: vinStr,
        deliveryStoreCodes : deliveryStoreCodes.length ? deliveryStoreCodes: this.deliveryStoreList.map(({ value })=>value),
        type: this.active
      }).then(res=>{
        console.log('res-----------', res)
        this.countNum = res?.page?.totalCount || 0
        return res
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      this.onRefresh()
      this.filterActive= false
    },
    handleConfigList(){
      this.$router.push({
        path: '/special-car-order-config',
        query:{ }
      })
    },
    handleOperate(){
      this.$router.push({
        path: '/special-car-stock/operate',
        query:{ 
          deliveryStoreCode: this.filterParams.deliveryStoreCodes,
          deliveryStoreName: this.deliveryStoreList.filter(({ value })=>this.filterParams.deliveryStoreCodes.includes(value))[0].dealerName,
        }
      })
    },
    handleDetail(item){
      this.$dialog.confirm({
        title: '',
        message: this.$t('创建配置单后，会根据OTD资源分配规则为您预留车辆，可能不是您查看到的VIN号'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('继续创建配置单'),
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            this.$router.push({
              path: '/special-car-stock/detail',
              query: {
                entryType: 'list',
                params: JSON.stringify(item)
              }
            })
          }
        },
      })
    },
  }
}
</script>

<style lang="less" scoped>
.specialStock {
  .special-stock-top{
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    .search-l{
      font-size: 14px;
      color: #7e7979;
      margin-right: 8px;
    }
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    // position: fixed;
    // right: 12px;
    // top: 12px;
  }
  .totality{
    padding: 10px 16px 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    .btn {
      color: #B9921A;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
