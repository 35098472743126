<template>
  <div class="SpecialStockItem">
    <div class="title-box">
      <div class="name">{{data.seriesCodeName}} {{ data.modelCodeName }}</div>
      <div class="type-name">{{getTypeName}}</div>
    </div>

    <div class="tags">
      <template v-for="(tag, index) of getTags">
        <div  v-if="tag.value" :key="index" class="tag" :class="tag.class">{{ tag.value }}</div>
      </template>
    </div>

    <div class="lines">
      <template v-for="(item, index) of getLines">
        <div  v-if="!item.isHide" :key="index" class="line">
          <div class="name">{{ item.name }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </template>
    </div>
    <div class="btns">
      <span @click.stop="handleClick()">创建同款配置单</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialStockItem',
  props:{
    data:{
      type: Object,
      default:()=>({})
    }
  },
  computed: {
    getLines() {
      const { optionsCodeName,vehicleNodeName,currentWarehouseName,deliveryStoreName,isOneCarOnePrice } = this.data
      return [
        {
          name: '选装',
          value: optionsCodeName
        },
        {
          name: '车辆节点',
          value: vehicleNodeName
        },
        {
          name: '当前仓库',
          value: currentWarehouseName
        },
        {
          name: '交付门店',
          value: deliveryStoreName
        },
      ]
    },
    getTags() {
      const { vin,interiorCodeName,colourCodeName } = this.data
      return [
        {
          name: '外饰',
          value: colourCodeName,
          class:'blue',
        },
        {
          name: '内饰',
          value: interiorCodeName,
          class:'green'
        },

        {
          name: 'VIN',
          value: vin,
          class:' lightBlue '
        },
      ]
    },
    getTypeName() {
      const obj={
        '40551002': '质损车',
        '40551003':'老龄车',
        '40551004':'Ni80车型',
      }
      return this.data.specialVehicleType && obj[this.data.specialVehicleType] || ''
    }
  },
  methods:{
    handleClick(){
      this.$emit('go')
    },
  }
}
</script>

<style lang="less" scoped>
.SpecialStockItem {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 12px;
  font-size: 13px;
  // margin: 10px 16px;
  .title-box {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 6px;

    .type-name {
      font-size: 13px;
    }
  }

  .lines {
    display: flex;
    flex-wrap: wrap;
    .line {
      display: flex;
      align-items: center;
      height: 24px;
      margin-bottom: 8px;
      &:first-child{
        width: 100%;
      }
      &:not(:first-child){
        width: 50%;
      }
      &:last-child{
        width: 100%;
      }
      .name {
        &::after {
          content: '：';
        }
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 8px;
    .tag {
      padding: 2px 8px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      margin-top: 8px;
      margin-left: 4px;
      line-height: 16px;
      font-size: 12px;

      &.blue {
        border-color: rgba(0, 64, 197, 0.1);
        color: #0040c5;
        background: rgba(0, 64, 197, 0.1);
      }
      &.green{
        color: #b9921a;
        background: rgba(185, 146, 26, 0.1);
        border-color: rgba(185, 146, 26, 0.1);
      }
      &.lightBlue {
        color: #029640;
        background: rgba(2, 150, 64, 0.1);
        border-color: rgba(2, 150, 64, 0.1);
      }
      // &:nth-of-type(4) {
      //   color: #666666;
      //   background: #efefef;
      //   border-color: #e1e1e1;
      // }
      &:last-child {
        margin-right: 4px;
      }
    }
  }
  .btns{
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-top: 1px solid rgba(13,23,26,0.05);
    span{
      flex:1;
      font-size: 16px;
      // padding: 8px 8px 0;
      font-family: HYQiHei-60S, HYQiHei;
      font-weight: normal;
      color: #B9921A;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
